import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/browser'
import $ from 'jquery'
import Behaviors from '../behaviors'

export default class barcodeScanner {
  constructor (element) {
    this.$element = $(element)
    this.$scannerContainer = $('.scannerContainer')
    this.$successMarker = this.$element.find('.formSuccess')

    this.$video = this.$element.find('.cameraPreview')
    this.$resultField = this.$element.find('.resultField')
    this.$form = this.$resultField.closest('form')
    this.$switchCamButton = this.$scannerContainer.find('.switchCam')
    this.$retryInitializeButton = this.$scannerContainer.find('.retryInitialize')

    this.$switchCamButton.on('click', this.handleSwitchCamButton.bind(this))
    this.$retryInitializeButton.on('click', this.handleRetryInitialize.bind(this))

    this.allowedFormats = this.$element.data('format')
    this.autoSubmitOnScan = this.$element.data('autoSubmitOnScan')

    this.camList = []
    this.currentCamId = null
    this.codeReader = null
    this.scanner = null

    this.initialize()
  }



  async getCamList () {
    this.camList = []

    const supportsMediaDevices = typeof navigator !== 'undefined' && !!navigator.mediaDevices
    if (!supportsMediaDevices) {
      return
    }

    await navigator.mediaDevices.getUserMedia({ audio: false, video: true })
    const devices = await navigator.mediaDevices.enumerateDevices()
    for (const device of devices) {
      if (device.kind === 'videoinput') {
        this.camList.push(device.deviceId)
      }
    }
  }

  handleRetryInitialize (event) {
    event.preventDefault()
    document.location.reload()
  }

  handleSwitchCamButton (event) {
    event.preventDefault()

    let nextCam = this.camList[0]
    for (let index = 0; index < this.camList.length; index++) {
      const camId = this.camList[index]
      if (camId === this.currentCamId) {
        let nextIndex = index + 1
        if (this.camList[nextIndex]) {
          nextCam = this.camList[nextIndex]
        }
      }
    }
    this.startScanner(nextCam)
  }

  getBarcodeFormatConstant (format) {
    switch (format) {
      case 'AZTEC': return BarcodeFormat.AZTEC
      case 'CODABAR': return BarcodeFormat.CODABAR
      case 'CODE_39': return BarcodeFormat.CODE_39
      case 'CODE_93': return BarcodeFormat.CODE_93
      case 'CODE_128': return BarcodeFormat.CODE_128
      case 'DATA_MATRIX': return BarcodeFormat.DATA_MATRIX
      case 'EAN_8': return BarcodeFormat.EAN_8
      case 'EAN_13': return BarcodeFormat.EAN_13
      case 'ITF': return BarcodeFormat.ITF
      case 'MAXICODE': return BarcodeFormat.MAXICODE
      case 'PDF_417': return BarcodeFormat.PDF_417
      case 'QR_CODE': return BarcodeFormat.QR_CODE
      case 'RSS_14': return BarcodeFormat.RSS_14
      case 'RSS_EXPANDED': return BarcodeFormat.RSS_EXPANDED
      case 'UPC_A': return BarcodeFormat.UPC_A
      case 'UPC_E': return BarcodeFormat.UPC_E
      case 'UPC_EAN_EXTENSION': return BarcodeFormat.UPC_EAN_EXTENSION
      default:
        console.error(`format "${format}" is not supported`)
        break
    }
    return null
  }

  async initialize () {
    await this.getCamList()

    if (this.camList.length > 1) {
      this.$switchCamButton.addClass('isMultiCam')
    }

    this.codeReader = new BrowserMultiFormatReader()

    const possibleFormats = []
    for (const format of this.allowedFormats) {
      const formatConstant = this.getBarcodeFormatConstant(format)
      if (formatConstant) {
        possibleFormats.push(formatConstant)
      }
    }

    this.codeReader.possibleFormats = possibleFormats
    this.startScanner()
  }

  async startScanner (deviceId) {
    const constraints = {
      audio: false,
      video: {
        width: {
          ideal: 1920
        },
        height: {
          ideal: 1080
        },
        facingMode: {
          ideal: 'environment'
        }
      }
    }

    if (!deviceId) {
      constraints.video.facingMode = {
        ideal: 'environment'
      }
    } else {
      this.currentCamId = deviceId
      constraints.video.deviceId = deviceId
    }

    if (this.scanner) {
      this.scanner.stop()
    }

    this.scanner = await this.codeReader.decodeFromConstraints(constraints, this.$video.get(0), this.handleScanResult.bind(this)).catch(e => {
      // console.log(e)
    })
  }

  handleScanResult (result, error, controls) {
    if (result) {
      this.determined(result.text)
    }

    if (error) {
      if (error.name !== 'NotFoundException') {
        console.error(error)
      }
    }
  }

  determined (code) {
    this.$resultField.val(code)
    this.autoSubmit()
  }

  autoSubmit () {
    if (this.autoSubmitOnScan) {
      this.$successMarker.addClass('active')
      this.scanner.stop()
      this.submitState = true
      this.$form.trigger('submit')
    }
  }
}
