import $ from 'jquery'

export default class navigationDropdownOnHover {
    constructor (element) {
        this.$element = $(element);
        this.$mainA = this.$element.children('a:first');
        this.$container = this.$element.children('ul.dropdown-menu');

        this.$element.on('mouseover', this.handleLinkOver.bind(this))
        this.$element.on('mouseout', this.handleLinkOut.bind(this))
        this.$element.on('click', this.handleClick.bind(this))

        this.closeTimeout = 500;
        this.closeTimer = null;
    }

    isActive() {
        return window.matchMedia("(min-width: 768px)").matches
    }

    handleLinkOver(event) {
        event.stopPropagation();
        console.log('handleOver');
        if (this.closeTimer) {
            window.clearTimeout(this.closeTimer)
        }
        this.open()
    }

    handleClick(event) {
        if (this.isActive()) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            window.location = this.$mainA.attr('href')
        }
    }

    handleLinkOut(event) {
        event.stopPropagation();
        console.log('handleLinkOut');
        this.closeTimer = window.setTimeout(this.close.bind(this), this.closeTimeout)
    }

    open() {
        if (!this.isActive()) {
            return
        }
        this.$container.addClass('show')
    }
    close() {
        if (!this.isActive()) {
            return
        }
        this.$container.removeClass('show')
    }



}