import Behavior from 'flamingo-carotene-behavior'
import * as behaviorModules0 from './modules/barcodeInput.js'; import * as behaviorModules1 from './modules/barcodeScanner.js'; import * as behaviorModules2 from './modules/cockpitMailSentInfo.js'; import * as behaviorModules3 from './modules/navigationDropdownOnHover.js'; import * as behaviorModules4 from './modules/scrollToError.js'; let behaviorModules = [behaviorModules0, behaviorModules1, behaviorModules2, behaviorModules3, behaviorModules4]

import BccSharedCockpitFrontend from 'bcc-shared-cockpit'
behaviorModules = BccSharedCockpitFrontend.addBehaviors(behaviorModules)

// Init Behaviors
const behavior = new Behavior(behaviorModules)
behavior.setDebug(false)
export default behavior
