import $ from 'jquery'
import './bootstrap'

// import BccSharedCockpitFrontend from 'bcc-shared-cockpit'
import Behaviors from './behaviors'
// import Tracking from './tracking'

$(document).ready(function () {
  // Tracking.initialize()
  // BccSharedCockpitFrontend.setTracking(Tracking)
  Behaviors.attachBehaviors(document)
})
