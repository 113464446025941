import $ from 'jquery'
import Cleave from 'cleave.js'

export default class barcodeInput {
  constructor (element) {
    this.$element = $(element)
    this.$codeInput = this.$element.find('input[type=text]')
    this.codeCleave = new Cleave(this.$codeInput.get(0), {
      delimiter: '-',
      blocks: [4, 4, 4],
      uppercase: true
    })
  }
}
